import React, { forwardRef } from 'react';
import { Spin } from 'antd';

const Index = ({ style = {}, ...prop }, _ref: any) => (
    <div className='' style={{ textAlign: 'center', paddingTop: '20vh', ...style }}>
        <Spin></Spin>
        <p style={{ fontSize: 14, color: '#666', marginTop: 10, }}>加载中...</p>
    </div>
);

export default forwardRef(Index);